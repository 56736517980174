import React from "react";

const Brands1 = ({ title, logo }) => {
  const speed = 10000;
  console.log("logo", logo);
  return (
    <>
      <section className="brand1">
        <div className="container py-5">
          <div className="row mt-5">
            <div className="row brandTitle text-center d-flex justify-content-center">
              <div className="col-md-8">
                <p className="brand_Title">{title}</p>
              </div>
            </div>
          </div>
          {/* <div className="my-5 brand_logo m-0 p-0">
            {logo?.map((x, i) => (
              <div className="mt-1 ml-3 at575BrandLogo bg-danger" key={i}>
                <img
                  src={x.imgUrl}
                  alt={x.name}
                  loading="lazy"
                  height="150"
                  width="250"
                  title={x.title}
                />
              </div>
            ))}
          </div> */}
          <div className="inner">
            <div className="wrapper">
              <section
                style={{ "--speed": `${speed}ms` }}
                className="brandSection"
              >
                {logo.map((x, i) => (
                  <div className="at575BrandLogo" key={i}>
                    <img
                      src={x.imgUrl}
                      alt={x.name}
                      loading="lazy"
                      height="150"
                      width="250"
                      title={x.title}
                    />
                  </div>
                ))}
              </section>
              <section
                style={{ "--speed": `${speed}ms` }}
                className="brandSection"
              >
                {logo.map((x, i) => (
                  <div className="at575BrandLogo" key={i}>
                    <img
                      src={x.imgUrl}
                      alt={x.name}
                      loading="lazy"
                      height="150"
                      width="250"
                      title={x.title}
                    />
                  </div>
                ))}
              </section>
              <section
                style={{ "--speed": `${speed}ms` }}
                className="brandSection"
              >
                {logo.map((x, i) => (
                  <div className="at575BrandLogo" key={i}>
                    <img
                      src={x.imgUrl}
                      alt={x.name}
                      loading="lazy"
                      height="150"
                      width="250"
                      title={x.title}
                    />
                  </div>
                ))}
              </section>
            </div>
          </div>
        </div>
      </section>
    </>
  );
};

export default Brands1;
