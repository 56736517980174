export let brandTitle1 = `
           Elevating Brands: A Gallery of Our Trusted Partnerships and Collaborations."

"Brand Symphony: Discover the Collective Essence of Our Collaborative Ventures and Esteemed Partnerships
  
  `;
export let brandTitle2 = `
            Experience innovation with Meta Certified AR, unlocking limitless possibilities in augmented reality. Elevate your online presence with cutting-edge immersive experiences.
  
  `;

export let BrandsLogo = [
  {
    imgUrl:
      "https://res.cloudinary.com/dxhvvxfqf/image/upload/v1718429943/BrandLogo/iimof9emm8gdrl2mw5ix.png",
    name: "Snap",
    title: "Snap",
  },
  {
    imgUrl:
      "https://res.cloudinary.com/dxhvvxfqf/image/upload/v1718429944/BrandLogo/af9qd5kjqtdmaavn8ght.png",
    name: "Flipkart",
    title: "Flipkart",
  },
  {
    imgUrl:
      "https://res.cloudinary.com/dxhvvxfqf/image/upload/v1718429944/BrandLogo/oqaqfqpbnefggow4ncgi.png",
    name: "ClipHealth",
    title: "ClipHealth",
  },
  {
    imgUrl:
      "https://res.cloudinary.com/dswdltf3l/image/upload/v1719326517/afsw7ypbltbfuww7xiim.png",
    name: "meta",
    title: "meta",
  },
  {
    imgUrl:
      "https://res.cloudinary.com/dxhvvxfqf/image/upload/v1718429945/BrandLogo/yyyfqdzvksv6ylpyhtmm.png",
    name: "kay",
  },
  {
    imgUrl:
      "https://res.cloudinary.com/dxhvvxfqf/image/upload/v1718429944/BrandLogo/svvuryunwa4bxdpkyfcd.png",
    name: "experi",
  },
  {
    imgUrl:
      "https://res.cloudinary.com/dxhvvxfqf/image/upload/v1718429944/BrandLogo/htyydgwpnnqqys1difzj.png",
    name: "sony",
  },
  {
    imgUrl:
      "https://res.cloudinary.com/dxhvvxfqf/image/upload/v1718429943/BrandLogo/q8sznvtcawbbbzeewvru.png",
    name: "zostel",
  },
  {
    imgUrl:
      "https://res.cloudinary.com/dxhvvxfqf/image/upload/v1718429943/BrandLogo/tlv6ygrpyomt2r8flnml.png",
    name: "ottno_tech",
  },
];

export let advancedLogo = [
  {
    imgUrl:
      "https://static.wixstatic.com/media/49687e_d60d35cd102f4965b601acde8c9b6122~mv2.png/v1/fill/w_248,h_248,al_c,q_85,usm_0.66_1.00_0.01,enc_auto/meta%20spark%20creator%20cert.png",
    name: "Ca",
  },
  {
    imgUrl:
      "https://static.wixstatic.com/media/49687e_8e028c51f88d429bb5cc1481d7d15925~mv2.png/v1/fill/w_193,h_248,al_c,q_85,usm_0.66_1.00_0.01,enc_auto/IMG_5222_PNG.png",
    name: "meta",
  },
  // {
  //   imgUrl:
  //     "https://static.wixstatic.com/media/49687e_f8956150fc834bc1b438493a27bbebbd~mv2.jpeg/v1/fill/w_248,h_248,al_c,q_80,usm_0.66_1.00_0.01,enc_auto/WhatsApp%20Image%202023-05-21%20at%2011_27_46.jpeg",
  //   name: "tomica",
  // },
];
